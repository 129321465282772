import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./GoogleAnalytics.css"; // Importieren Sie die CSS-Datei für die Stile

const TrackingBanner = ({ onConsent }) => {
  const [consentGiven, setConsentGiven] = useState(false);
  const [showBanner, setShowBanner] = useState(true); // Show Banner standardmäßig anzeigen
  const [trackingSettings, setTrackingSettings] = useState({
    cookies: false,
    googleAnalytics: false,
  });
  const [showCustomSettings, setShowCustomSettings] = useState(false); // Anzeigen der benutzerdefinierten Einstellungen

  useEffect(() => {
    const consentStatus = localStorage.getItem("analyticsConsent");
    setConsentGiven(consentStatus === "true");
    setShowBanner(consentStatus !== "true"); // Banner nur anzeigen, wenn Zustimmung noch nicht erteilt wurde

    const storedSettings = localStorage.getItem("trackingSettings");
    if (storedSettings) {
      setTrackingSettings(JSON.parse(storedSettings));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("trackingSettings", JSON.stringify(trackingSettings));
  }, [trackingSettings]);

  const handleConsentAll = () => {
    localStorage.setItem("analyticsConsent", "true");
    setConsentGiven(true);
    setShowBanner(false);
    onConsent();
  };

  const handleConsentCustom = () => {
    setShowCustomSettings(true);
    setShowBanner(false);
  };

  const handleConfirmSettings = () => {
    const anyTrackingEnabled = Object.values(trackingSettings).some(
      (value) => value
    );

    if (anyTrackingEnabled) {
      localStorage.setItem("analyticsConsent", "true");
      setConsentGiven(true);
      setShowCustomSettings(false);
      onConsent();
    } else {
      // Wenn keine Tracking-Kategorie ausgewählt wurde, zeigen Sie eine Fehlermeldung an oder führen Sie eine entsprechende Aktion durch
      alert("Bitte wählen Sie mindestens eine Tracking-Kategorie aus.");
    }
  };

  const handleTrackingToggle = (category) => {
    setTrackingSettings((prevSettings) => ({
      ...prevSettings,
      [category]: !prevSettings[category],
    }));
  };

  return (
    <>
      {!consentGiven && showBanner && (
        <div className="tracking-banner">
          <div className="google-analytics-banner">
            <p>
              Diese Website verwendet Cookies, um Ihre Erfahrung zu verbessern.
              Indem Sie diese Website nutzen, stimmen Sie der Verwendung von
              Cookies zu. Weitere Informationen finden Sie in unserer{" "}
              <a href="/#/privacy-policy">Datenschutzerklärung</a>.
            </p>
            <button onClick={handleConsentAll}>Allen Cookies zustimmen</button>
            <button onClick={handleConsentCustom}>
              Individuelle Einstellungen
            </button>
          </div>
        </div>
      )}
      {showCustomSettings && (
        <div className="tracking-banner">
          <div className="google-analytics-banner">
            <p>Wählen Sie die Tracking-Kategorien aus:</p>
            <div className="tracking-settings">
              <div className="checkbox-container">
                <input
                  type="checkbox"
                  checked={trackingSettings.cookies}
                  onChange={() => handleTrackingToggle("cookies")}
                />
                <span>Cookies</span>
              </div>
              <div className="checkbox-container">
                <input
                  type="checkbox"
                  checked={trackingSettings.googleAnalytics}
                  onChange={() => handleTrackingToggle("googleAnalytics")}
                />
                <span>Google Analytics</span>
              </div>
            </div>

            <button onClick={handleConfirmSettings}>Auswahl bestätigen</button>
          </div>
        </div>
      )}
    </>
  );
};

TrackingBanner.propTypes = {
  onConsent: PropTypes.func.isRequired,
};

export default TrackingBanner;
