import React from "react";

function AboutUs() {
  return (
    <div className="about-container">
      <div className="about-image-container">
        <img src="./IMG_5226.jpg" alt="Fotograf" className="about-img" />
        <div className="subtitle-container">
          <div className="about-subtitle">
            <strong>Ömer Özer</strong>
            <br />
            Fotograf
          </div>
        </div>
      </div>
      <div className="about-text">
        <h2>Über mich</h2>
        <p>
          Bei Özer Media liegt die Leidenschaft darin, die kostbarsten Momente
          Ihres besonderen Tages einzufangen. Durch jahrelange Erfahrung strebe
          ich danach, qualitativ hochwertige und unvergessliche Bilder zu
          liefern.
        </p>
      </div>
    </div>
  );
}

export default AboutUs;
