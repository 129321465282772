import React, { useState } from "react";
import "./WeddingGallery.css"; // Importiere die CSS-Datei

function WeddingGallery() {
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);

  const photos = [
    { src: "/images/weddingImages/OEZPH-111.JPG" },
    { src: "/images/weddingImages/OEZPH-120.JPG" },
    { src: "/images/weddingImages/OEZPH-157.JPG" },
    { src: "/images/weddingImages/OEZPH-200.JPG" },
    { src: "/images/weddingImages/OEZPH-232.JPG" },
    { src: "/images/weddingImages/OEZPH-36.JPG" },
    { src: "/images/weddingImages/OEZPH-75.JPG" },
    { src: "/images/weddingImages/OEZMD-90.webp" },
    { src: "/images/weddingImages/OEZMD-183.webp" },
    { src: "/images/weddingImages/OEZMD-424.webp" },
    { src: "/images/weddingImages/OEZMD-425.webp" },
    { src: "/images/weddingImages/OEZMD-542.webp" },
    { src: "/images/weddingImages/OEZMD-69.webp" },
    { src: "/images/weddingImages/OEZMD-121.webp" },
    { src: "/images/weddingImages/OEZMD-192.webp" },
    { src: "/images/weddingImages/OEZMD-318.webp" },
    { src: "/images/weddingImages/OEZMD-331.webp" },
    { src: "/images/weddingImages/OEZMD-430.webp" },
    { src: "/images/weddingImages/OEZMD-595.webp" },
    { src: "/images/weddingImages/OEZPH-28.webp" },
    { src: "/images/weddingImages/OEZPH-41.webp" },
    { src: "/images/weddingImages/OEZPH-72.webp" },
    { src: "/images/weddingImages/OEZPH-123.webp" },
    { src: "/images/weddingImages/IMG_1715.webp" },
  ];

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <div>
      <h2>Wedding Gallery</h2>
      <div className="wedding-gallery">
        {photos.map((photo, index) => (
          <div key={index} className="wedding-gallery-img">
            <img
              src={process.env.PUBLIC_URL + photo.src}
              alt={`Photo ${index + 1}`}
              onClick={() => openLightbox(index)}
            />
          </div>
        ))}
      </div>
      {viewerIsOpen && (
        <div className="overlay" onClick={closeLightbox}>
          <div className="modal" onClick={(e) => e.stopPropagation()}>
            <button className="modal-close" onClick={closeLightbox}>
            Schließen
            </button>
            <img
              src={process.env.PUBLIC_URL + photos[currentImage].src}
              alt={`Photo ${currentImage + 1}`}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default WeddingGallery;
