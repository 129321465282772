import React from "react";

const Datenschutzerklaerung = () => {
  return (
    <div>
      <h1>Datenschutzerklärung</h1>
      <p>
        Diese Datenschutzerklärung klärt Sie über die Art, den Umfang und Zweck
        der Verarbeitung von personenbezogenen Daten (nachfolgend kurz „Daten“)
        innerhalb unseres Onlineangebotes und der mit ihm verbundenen Webseiten,
        Funktionen und Inhalte sowie externen Onlinepräsenzen, wie z.B. unser
        Social Media Profile auf (nachfolgend gemeinsam bezeichnet als
        „Onlineangebot“). Im Hinblick auf die verwendeten Begrifflichkeiten,
        wie z.B. „Verarbeitung“ oder „Verantwortlicher“ verweisen wir auf die
        Definitionen im Art. 4 der Datenschutzgrundverordnung (DSGVO).
      </p>
      <h2>Verantwortlicher</h2>
      <p>
        Ömer Selcuk Özer
        <br />
        Steinacker II 22
        <br />
        76684 Östringen
        <br />
        E-Mail: oezermedia@outlook.com
      </p>
      <h2>Arten der verarbeiteten Daten</h2>
      <p>
        - Bestandsdaten (z.B., Namen, Adressen).
        <br />
        - Kontaktdaten (z.B., E-Mail, Telefonnummern).
        <br />
        - Inhaltsdaten (z.B., Texteingaben, Fotografien, Videos).
        <br />
        - Nutzungsdaten (z.B., besuchte Webseiten, Interesse an Inhalten,
        Zugriffszeiten).
        <br />
        - Meta-/Kommunikationsdaten (z.B., Geräte-Informationen, IP-Adressen).
      </p>
      <h2>Kategorien betroffener Personen</h2>
      <p>
        Besucher und Nutzer des Onlineangebotes (Nachfolgend bezeichnen wir die
        betroffenen Personen zusammenfassend auch als „Nutzer“).
      </p>
      <h2>Zweck der Verarbeitung</h2>
      <p>
        - Zurverfügungstellung des Onlineangebotes, seiner Funktionen und Inhalte.
        <br />
        - Beantwortung von Kontaktanfragen und Kommunikation mit Nutzern.
        <br />
        - Sicherheitsmaßnahmen.
        <br />
        - Reichweitenmessung/Marketing
      </p>
      <h2>Rechtsgrundlagen der Verarbeitung</h2>
      <p>
        Im Folgenden erhalten Sie einen Überblick über die Rechtsgrundlagen der
        DSGVO, auf deren Basis wir personenbezogene Daten verarbeiten. Bitte
        beachten Sie, dass zusätzlich zu den Regelungen der DSGVO die
        nationalen Datenschutzvorgaben in Ihrem bzw. unserem Wohn- und
        Sitzland gelten können.
      </p>
      <p>
        - Einwilligung (Art. 6 Abs. 1 S. 1 lit. a DSGVO) - Die betroffene Person
        hat ihre Einwilligung in die Verarbeitung der sie betreffenden
        personenbezogenen Daten für einen spezifischen Zweck oder mehrere
        bestimmte Zwecke gegeben.
        <br />
        - Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1
        lit. b. DSGVO) - Die Verarbeitung ist für die Erfüllung eines Vertrags,
        dessen Vertragspartei die betroffene Person ist, oder zur Durchführung
        vorvertraglicher Maßnahmen erforderlich, die auf Anfrage der
        betroffenen Person erfolgen.
        <br />
        - Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO) - Die
        Verarbeitung ist zur Wahrung der berechtigten Interessen des
        Verantwortlichen oder eines Dritten erforderlich, sofern nicht die
        Interessen oder Grundrechte und Grundfreiheiten der betroffenen Person,
        die den Schutz personenbezogener Daten erfordern, überwiegen.
      </p>
      <h2>Maßgebliche Rechtsgrundlagen</h2>
      <p>
        Nach Maßgabe des Art. 13 DSGVO teilen wir Ihnen die Rechtsgrundlagen
        unserer Datenverarbeitungen mit. Sofern die Rechtsgrundlage in der
        Datenschutzerklärung nicht genannt wird, gilt Folgendes: Die
        Rechtsgrundlage für die Einholung von Einwilligungen ist Art. 6 Abs. 1
        lit. a und Art. 7 DSGVO, die Rechtsgrundlage für die Verarbeitung zur
        Erfüllung unserer Leistungen und Durchführung vertraglicher Maßnahmen
        sowie Beantwortung von Anfragen ist Art. 6 Abs. 1 lit. b DSGVO, die
        Rechtsgrundlage für die Verarbeitung zur Erfüllung unserer rechtlichen
        Verpflichtungen ist Art. 6 Abs. 1 lit. c DSGVO, und die Rechtsgrundlage
        für die Verarbeitung zur Wahrung unserer berechtigten Interessen ist Art.
        6 Abs. 1 lit. f DSGVO. Für den Fall, dass lebenswichtige Interessen der
        betroffenen Person oder einer anderen natürlichen Person eine
        Verarbeitung personenbezogener Daten erforderlich machen, dient Art. 6
        Abs. 1 lit. d DSGVO als Rechtsgrundlage.
      </p>
      <h2>Sicherheitsmaßnahmen</h2>
      <p>
        Wir treffen nach Maßgabe des Art. 32 DSGVO unter Berücksichtigung des
        Stands der Technik, der Implementierungskosten und der Art, des Umfangs,
        der Umstände und der Zwecke der Verarbeitung sowie der unterschiedlichen
        Eintrittswahrscheinlichkeit und Schwere des Risikos für die Rechte und
        Freiheiten natürlicher Personen, geeignete technische und
        organisatorische Maßnahmen, um ein dem Risiko angemessenes Schutzniveau
        zu gewährleisten.
      </p>
      <p>
        Zu den Maßnahmen gehören insbesondere die Sicherung der Vertraulichkeit,
        Integrität und Verfügbarkeit von Daten durch Kontrolle des physischen
        Zugangs zu den Daten, als auch des sie betreffenden Zugriffs, der
        Eingabe, Weitergabe, der Sicherung der Verfügbarkeit und ihrer Trennung.
      </p>
      <p>
        Des Weiteren haben wir Verfahren eingerichtet, die eine Wahrnehmung von
        Betroffenenrechten, Löschung von Daten und Reaktion auf Gefährdung der
        Daten gewährleisten. Ferner berücksichtigen wir den Schutz personenbezogener
        Daten bereits bei der Entwicklung, bzw. Auswahl von Hardware, Software
        sowie Verfahren, entsprechend dem Prinzip des Datenschutzes durch
        Technikgestaltung und durch datenschutzfreundliche Voreinstellungen.
      </p>
      <h2>Zusammenarbeit mit Auftragsverarbeitern und Dritten</h2>
      <p>
        Sofern wir im Rahmen unserer Verarbeitung Daten gegenüber anderen
        Personen und Unternehmen (Auftragsverarbeitern oder Dritten) offenbaren,
        sie an diese übermitteln oder ihnen sonst Zugriff auf die Daten
        gewähren, erfolgt dies nur auf Grundlage einer gesetzlichen Erlaubnis
        (z.B. wenn eine Übermittlung der Daten an Dritte, wie an Zahlungsdienstleister,
        gem. Art. 6 Abs. 1 lit. b DSGVO zur Vertragserfüllung erforderlich ist),
        Sie eingewilligt haben, eine rechtliche Verpflichtung dies vorsieht
        oder auf Grundlage unserer berechtigten Interessen (z.B. beim Einsatz
        von Beauftragten, Webhostern, etc.).
      </p>
      <p>
        Sofern wir Dritte mit der Verarbeitung von Daten auf Grundlage eines
        sog. „Auftragsverarbeitungsvertrages“ beauftragen, geschieht dies auf
        Grundlage des Art. 28 DSGVO.
      </p>
      <h2>Übermittlungen in Drittländer</h2>
      <p>
        Sofern wir Daten in einem Drittland (d.h. außerhalb der Europäischen
        Union (EU) oder des Europäischen Wirtschaftsraums (EWR)) verarbeiten
        oder dies im Rahmen der Inanspruchnahme von Diensten Dritter oder
        Offenlegung, bzw. Übermittlung von Daten an Dritte geschieht, erfolgt
        dies nur, wenn es zur Erfüllung unserer (vor)vertraglichen Pflichten,
        auf Grundlage Ihrer Einwilligung, aufgrund einer rechtlichen Verpflichtung
        oder auf Grundlage unserer berechtigten Interessen geschieht. Vorbehaltlich
        gesetzlicher oder vertraglicher Erlaubnisse, verarbeiten oder lassen wir
        die Daten in einem Drittland nur beim Vorliegen der besonderen Voraussetzungen
        der Art. 44 ff. DSGVO verarbeiten. D.h. die Verarbeitung erfolgt z.B. auf
        Grundlage besonderer Garantien, wie der offiziell anerkannten Feststellung
        eines der EU entsprechenden Datenschutzniveaus (z.B. für die USA durch das
        „Privacy Shield“) oder Beachtung offiziell anerkannter spezieller vertraglicher
        Verpflichtungen (so genannte „Standardvertragsklauseln“).
      </p>
      <h2>Rechte der betroffenen Personen</h2>
      <p>
        Ihnen stehen verschiedene Rechte zu, die sich aus der DSGVO ergeben,
        insbesondere aus den Artikeln 15 bis 18 und 21 DSGVO:
      </p>
      <ul>
        <li>
          - Recht auf Auskunft: Sie haben das Recht, eine Bestätigung darüber zu
          verlangen, ob betreffende Daten verarbeitet werden und auf Auskunft über
          diese Daten sowie auf weitere Informationen und Kopie der Daten
          entsprechend den gesetzlichen Vorgaben.
        </li>
        <li>
          - Recht auf Berichtigung: Sie haben entsprechend den gesetzlichen
          Vorgaben das Recht, die Vervollständigung der Sie betreffenden Daten oder
          die Berichtigung der Sie betreffenden unrichtigen Daten zu verlangen.
        </li>
        <li>
          - Recht auf Löschung und Einschränkung der Verarbeitung: Sie haben nach
          Maßgabe der gesetzlichen Vorgaben das Recht, zu verlangen, dass Sie
          betreffende Daten unverzüglich gelöscht werden, bzw. alternativ nach
          Maßgabe der gesetzlichen Vorgaben eine Einschränkung der Verarbeitung der
          Daten zu verlangen.
        </li>
        <li>
          - Recht auf Datenübertragbarkeit: Sie haben das Recht, Sie betreffende
          Daten, die Sie uns bereitgestellt haben, nach Maßgabe der gesetzlichen
          Vorgaben in einem strukturierten, gängigen und maschinenlesbaren Format
          zu erhalten oder deren Übermittlung an einen anderen Verantwortlichen zu
          fordern.
        </li>
        <li>
          - Beschwerde bei Aufsichtsbehörde: Sie haben ferner nach Maßgabe der
          gesetzlichen Vorgaben das Recht, bei einer Aufsichtsbehörde, insbesondere
          in dem Mitgliedstaat Ihres gewöhnlichen Aufenthaltsorts, Ihres Arbeitsplatzes
          oder des Orts des mutmaßlichen Verstoßes Beschwerde einzulegen, wenn Sie
          der Ansicht sind, dass die Verarbeitung der Sie betreffenden personenbezogenen
          Daten gegen die DSGVO verstößt.
        </li>
      </ul>
      <h2>Widerrufsrecht</h2>
      <p>
        Sie haben das Recht, erteilte Einwilligungen mit Wirkung für die Zukunft
        zu widerrufen.
      </p>
      <h2>Widerspruchsrecht</h2>
      <p>
        Sie können der künftigen Verarbeitung der Sie betreffenden Daten nach
        Maßgabe der gesetzlichen Vorgaben jederzeit widersprechen. Der Widerspruch
        kann insbesondere gegen die Verarbeitung für Zwecke der Direktwerbung erfolgen.
      </p>
      <h2>Cookies und Widerspruchsrecht bei Direktwerbung</h2>
      <p>
        Als „Cookies“ werden kleine Dateien bezeichnet, die auf Rechnern der Nutzer
        gespeichert werden. Innerhalb der Cookies können unterschiedliche Angaben
        gespeichert werden. Ein Cookie dient primär dazu, die Angaben zu einem
        Nutzer (bzw. dem Gerät auf dem das Cookie gespeichert ist) während oder
        auch nach seinem Besuch innerhalb eines Onlineangebotes zu speichern.
        Als temporäre Cookies, bzw. „Session-Cookies“ oder „transiente Cookies“,
        werden Cookies bezeichnet, die gelöscht werden, nachdem ein Nutzer ein
        Onlineangebot verlässt und seinen Browser schließt. In einem solchen Cookie
        kann z.B. der Inhalt eines Warenkorbs in einem Onlineshop oder ein
        Login-Status gespeichert werden. Als „permanent“ oder „persistent“ werden
        Cookies bezeichnet, die auch nach dem Schließen des Browsers gespeichert
        bleiben. So kann z.B. der Login-Status gespeichert werden, wenn die Nutzer
        diese nach mehreren Tagen aufsuchen. Ebenso können in einem solchen Cookie
        die Interessen der Nutzer gespeichert werden, die für Reichweitenmessung
        oder Marketingzwecke verwendet werden. Als „Third-Party-Cookie“ werden
        Cookies bezeichnet, die von anderen Anbietern als dem Verantwortlichen,
        der das Onlineangebot betreibt, angeboten werden (andernfalls, wenn es nur
        dessen Cookies sind spricht man von „First-Party Cookies“).
      </p>
      <p>
        Wir können temporäre und permanente Cookies einsetzen und klären hierüber
        im Rahmen unserer Datenschutzerklärung auf.
      </p>
      <p>
        Falls die Nutzer nicht möchten, dass Cookies auf ihrem Rechner gespeichert
        werden, werden sie gebeten die entsprechende Option in den Systemeinstellungen
        ihres Browsers zu deaktivieren. Gespeicherte Cookies können in den
        Systemeinstellungen des Browsers gelöscht werden. Der Ausschluss von Cookies
        kann zu Funktionseinschränkungen dieses Onlineangebotes führen.
      </p>
      <p>
        Ein genereller Widerspruch gegen den Einsatz der zu Zwecken des Onlinemarketing
        eingesetzten Cookies kann bei einer Vielzahl der Dienste, vor allem im Fall
        des Trackings, über die US-amerikanische Seite
        <a href="http://www.aboutads.info/choices/">http://www.aboutads.info/choices/</a>
        oder die EU-Seite <a href="http://www.youronlinechoices.com/">http://www.youronlinechoices.com/</a>
        erklärt werden. Des Weiteren kann die Speicherung von Cookies mittels deren
        Abschaltung in den Einstellungen des Browsers erreicht werden. Bitte beachten
        Sie, dass dann gegebenenfalls nicht alle Funktionen dieses Onlineangebotes
        genutzt werden können.
      </p>
      <h2>Löschung von Daten</h2>
      <p>
        Die von uns verarbeiteten Daten werden nach Maßgabe der gesetzlichen
        Vorgaben gelöscht oder in ihrer Verarbeitung eingeschränkt. Sofern nicht
        im Rahmen dieser Datenschutzerklärung ausdrücklich angegeben, werden die
        bei uns gespeicherten Daten gelöscht, sobald sie für ihre Zweckbestimmung
        nicht mehr erforderlich sind und der Löschung keine gesetzlichen
        Aufbewahrungspflichten entgegenstehen. Sofern die Daten nicht gelöscht
        werden, weil sie für andere und gesetzlich zulässige Zwecke erforderlich
        sind, wird deren Verarbeitung eingeschränkt. D.h. die Daten werden gesperrt
        und nicht für andere Zwecke verarbeitet. Das gilt z.B. für Daten, die aus
        handels- oder steuerrechtlichen Gründen aufbewahrt werden müssen.
      </p>
      <h2>Geschäftsbezogene Verarbeitung</h2>
      <p>
        Zusätzlich verarbeiten wir
      </p>
      <ul>
        <li>
          - Vertragsdaten (z.B., Vertragsgegenstand, Laufzeit, Kundenkategorie).
        </li>
        <li>
          - Zahlungsdaten (z.B., Bankverbindung, Zahlungshistorie)
        </li>
      </ul>
      <p>
        von unseren Kunden, Interessenten und Geschäftspartner zwecks Erbringung
        vertraglicher Leistungen, Service und Kundenpflege, Marketing, Werbung und
        Marktforschung.
      </p>
      <h2>Kontaktaufnahme</h2>
      <p>
        Bei der Kontaktaufnahme mit uns (z.B. per Kontaktformular, E-Mail,
        Telefon oder via sozialer Medien) werden die Angaben des Nutzers zur
        Bearbeitung der Kontaktanfrage und deren Abwicklung gem. Art. 6 Abs. 1 lit.
        b. (im Rahmen vertraglicher-/vorvertraglicher Beziehungen), Art. 6 Abs. 1
        lit. f. (andere Anfragen) DSGVO verarbeitet.. Die Angaben der Nutzer können
        in einem Customer-Relationship-Management System (&quot;CRM System&quot;) oder
        vergleichbarer Anfragenorganisation gespeichert werden.
      </p>
      <p>
        Wir löschen die Anfragen, sofern diese nicht mehr erforderlich sind. Wir
        überprüfen die Erforderlichkeit alle zwei Jahre; Ferner gelten die
        gesetzlichen Archivierungspflichten.
      </p>
    </div>
  );
};

export default Datenschutzerklaerung;
