import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

function Header() {
  const [isMobile, setIsMobile] = useState(false);
  const location = useLocation();
  const isInDigitalBusinessCard = location.pathname.includes("/digital-business-card");

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <header className="header">
      <div className="container">
        <div className="logo">
          {isInDigitalBusinessCard ? (
            <img src="/OezerMedia-Logo-rund.png" alt="Your Logo" />
          ) : (
            <Link to="/">
              <img src="/OezerMedia-Logo-rund.png" alt="Your Logo" />
            </Link>
          )}
        </div>
        <nav>
          {(!isInDigitalBusinessCard || isMobile) && (
            <ul className="menu-items">
              {isMobile ? null : (
                <li>
                  <Link to="/">Home</Link>
                </li>
              )}
              <li>
                <Link to="/portfolio">Portfolio</Link>
              </li>
              <li>
                <Link to="/services">Services</Link>
              </li>
              <li>
                <Link to="/about-us">About Us</Link>
              </li>
              {/* <li>
                <Link to="/contact">Contact</Link>
              </li> */}
            </ul>
          )}
        </nav>
      </div>
    </header>
  );
}

export default Header;
