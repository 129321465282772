import React, { useState } from "react";
import "./BusinessGallery.css"; // Importiere die CSS-Datei

function BusinessGallery() {
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);

  const photos = [
    { src: "/images/businessImages/Bewerbungsbild_Hochformat.webp" },
    { src: "/images/businessImages/OEZMD-13.webp" },
    { src: "/images/businessImages/OEZMD-14.webp" },
    { src: "/images/businessImages/OEZMD-32.webp" },
    // Weitere Geschäftsbilder hinzufügen...
  ];

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <div>
      <h2>Business Gallery</h2>
      <div className="business-gallery">
        {photos.map((photo, index) => (
          <div key={index} className="business-gallery-img">
            <img
              src={process.env.PUBLIC_URL + photo.src}
              alt={`Photo ${index + 1}`}
              onClick={() => openLightbox(index)}
            />
          </div>
        ))}
      </div>
      {viewerIsOpen && (
        <div className="overlay" onClick={closeLightbox}>
          <div className="modal" onClick={(e) => e.stopPropagation()}>
            <button className="modal-close" onClick={closeLightbox}>
              Schließen
            </button>
            <img
              src={process.env.PUBLIC_URL + photos[currentImage].src}
              alt={`Photo ${currentImage + 1}`}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default BusinessGallery;
