/* eslint-disable no-undef */

import React, { useMemo, useEffect, useState } from "react";
import { Link } from "react-router-dom";

function preloadImages(images) {
  images.forEach((image) => {
    const img = new Image();
    img.src = `${process.env.PUBLIC_URL}/images/${image.filename}`;
  });
}

function Portfolio() {
  const [imagesLoaded, setImagesLoaded] = useState(false);

  const galleryImages = useMemo(
    () => [
      {
        filename: "portfolioImages/OEZMD3.jpg",
        subtitle: "Emotionale Hochzeitsmomente",
        link: "/wedding-gallery",
      },
      {
        filename: "portfolioImages/IMG1595.jpg",
        subtitle: "Unternehmensimpressionen",
        link: "/business-gallery",
      },
      {
        filename: "portfolioImages/OEZMD352.jpg",
        subtitle: "Familiäre Erinnerungen",
        link: "/family-gallery",
      },
      {
        filename: "portfolioImages/OEZMD4.jpg",
        subtitle: "Persönliche Porträts",
        link: "/portrait-gallery",
      },
    ],
    []
  );

  useEffect(() => {
    if (!imagesLoaded) {
      preloadImages(galleryImages);
      setImagesLoaded(true);
    }
  }, [galleryImages, imagesLoaded]);

  return (
    <div>
      <h2 className="portfolio-title">Portfolio</h2>
      <div className="gallery">
        {galleryImages.map((image, index) => (
          <Link key={index} to={image.link} className="lazy-link">
            <div className="image-container">
              <img
                src={`${process.env.PUBLIC_URL}/images/${image.filename}`}
                alt={image.subtitle}
                className="gallery-img"
              />
              <div className="subtitle">
                <strong>{image.subtitle}</strong>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default Portfolio;
