import React from "react";
import { motion } from "framer-motion";



function Home() {
  return (
    <div className="container">
      <div className="header">
        <h2>Willkommen bei Özer Media</h2>
        <p className="intro">
          Treten Sie ein in eine Welt, in der jedes Foto und jedes Video eine
          Geschichte erzählt. Ich bin hier, um Ihre einzigartigen Momente
          festzuhalten – sei es durch Porträts, Hochzeiten, Familienbilder oder
          Unternehmensaufnahmen.
        </p>
      </div>
      <div className="content">
        <div className="text-container">
          <div className="benefits">
            <h3>Für jeden Anlass, für jeden Moment</h3>
            <p>
              Ob als Teil eines glücklichen Paares, einer liebevollen Familie,
              eines erfolgreichen Unternehmens oder als Individuum – ich bin
              hier, um Ihre Visionen zum Leben zu erwecken. Lassen Sie uns
              gemeinsam die Magie der Bilder entdecken und Erinnerungen
              schaffen, die ein Leben lang halten.
            </p>
          </div>
          <div className="contact">
            <h3>Kontaktieren Sie mich heute</h3>
            <p>
              Lassen Sie mich Ihnen helfen, Ihre Momente für immer festzuhalten.
              Kontaktieren Sie mich noch heute, um Ihre Reise durch die Welt der
              Fotografie und Videografie zu beginnen.{" "}
            </p>
            <p>Tauchen Sie ein in die Welt der Emotionen mit Özer Media.</p>
            <h4>
              <em>Jeder Moment verdient es, festgehalten zu werden..</em>
            </h4>
          </div>
        </div>
        <motion.div
          className="image-container"
          initial={{ x: "100vw" }}
          animate={{ x: 0 }}
          transition={{ duration: 1 }}
        >
          <motion.img
            src="/images/OEZMD-3.jpg" // Passe den Pfad zum Bild entsprechend an
            alt="Bildunterschrift"
            style={{ width: "50%", height: "auto" }} // Hier wird die Breite des Bildes auf 50% gesetzt
          />
        </motion.div>
      </div>
    </div>
  );
}

export default Home;
