import React, { useState } from "react";
import {
  HashRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom"; // Änderung der Importanweisung
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./components/Home";
import Portfolio from "./components/Portfolio";
import Services from "./components/Services";
import AboutUs from "./components/AboutUs";
import Contact from "./components/Contact";
import DigitalBusinessCard from "./components/DigitalBusinessCard/DigitalBusinessCard";

import WeddingGallery from "./components/WeddingGallery"; // Neu hinzugefügt
import BusinessGallery from "./components/BusinessGallery"; // Neu hinzugefügt
import FamilyGallery from "./components/FamilyGallery"; // Neu hinzugefügt
import PortraitGallery from "./components/PortraitGallery"; // Neu hinzugefügt
import Datenschutzerklaerung from "./components/Datenschutzerklaerung";
import Impressum from "./components/Impressum";
import TrackingBanner from "./TrackingBanner";
import "./App.css";

function App() {
  const [consentGiven, setConsentGiven] = useState(false);

  const handleConsent = () => {
    setConsentGiven(true);
  };

  return (
    <Router>
      {/* Ändern Sie den Router zu HashRouter */}
      <div>
        <Header />
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/services" element={<Services />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/wedding-gallery" element={<WeddingGallery />} />{" "}
            {/* Neu hinzugefügt */}
            <Route
              path="/business-gallery"
              element={<BusinessGallery />}
            />{" "}
            {/* Neu hinzugefügt */}
            <Route path="/family-gallery" element={<FamilyGallery />} />{" "}
            {/* Neu hinzugefügt */}
            <Route
              path="/portrait-gallery"
              element={<PortraitGallery />}
            />{" "}
            {/* Neu hinzugefügt */}
            <Route
              path="/digital-business-card"
              element={<DigitalBusinessCard />}
            />
            <Route
              path="/"
              element={<Navigate to="/digital-business-card" replace />}
            />
            <Route path="/privacy-policy" element={<Datenschutzerklaerung />} />
            <Route path="/imprint" element={<Impressum />} />
          </Routes>
        </main>
        <Footer />
        <TrackingBanner consentGiven={consentGiven} onConsent={handleConsent} />
      </div>
    </Router>
  );
}

export default App;
