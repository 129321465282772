import React from "react";
import "./Impressum.css";

const Impressum = () => {
  return (
    <div className="impressum-container">
      <h1 className="impressum-heading">Impressum</h1>
      
      <section className="impressum-section">
        <h2>Angaben gemäß § 5 TMG:</h2>
        <p>
          Ömer Selcuk Özer <br />
          Fotograf / Videograf <br />
          Tel: 0621 15962914 <br />
          E-Mail: oezermedia(at)outlook(dot)com <br />
          76684 Östringen
        </p>
      </section>
      
      <section className="impressum-section">
        <h2>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:</h2>
        <p>Ömer Selcuk Özer</p>
      </section>
      
      <section className="impressum-section">
        <h2>Verantwortungsbereich:</h2>
        <p>Das Impressum gilt für die Internetpräsenz unter der Domain: www.oezermedia.com</p>
      </section>
      
      <section className="impressum-section">
        <h2>Copyright:</h2>
        <p>
          Alle Inhalte dieser Website, einschließlich Texte, Bilder und andere veröffentlichte Werke, unterliegen dem Copyright von Ömer Selcuk Özer, sofern nicht anders gekennzeichnet. Jegliche Vervielfältigung, Verbreitung, Speicherung, Übermittlung, Sendung und Weitergabe der Inhalte ist ohne vorherige schriftliche Genehmigung von Ömer Selcuk Özer ausdrücklich untersagt.
        </p>
        <p>
          Logos, Grafiken, Texte, Bilder oder Videos auf dieser Website dürfen nur nach vorheriger schriftlicher Genehmigung durch Ömer Selcuk Özer kopiert, vervielfältigt, geändert, veröffentlicht, heruntergeladen, versendet, übertragen oder anderweitig genutzt werden.
        </p>
      </section>
      
      <section className="impressum-section">
        <h2>Haftungsausschluss:</h2>
        <p>
          Wir nehmen den Schutz Ihrer persönlichen Daten ernst und halten uns an die Regeln der Datenschutzgesetze. Personenbezogene Daten werden auf dieser Website nur im technisch notwendigen Umfang erhoben und nicht an Dritte weitergegeben.
        </p>
        <p>
          Die Inhalte dieser Website wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte übernehmen wir jedoch keine Gewähr. Als Dienstanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Dienstanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
        </p>
      </section>
      
      <section className="impressum-section">
        <h2>Haftung für Links:</h2>
        <p>
          Diese Website enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben. Für die Inhalte der verlinkten Websites ist stets der jeweilige Anbieter oder Betreiber der Websites verantwortlich. Wir übernehmen keine Haftung für die Inhalte und Datenschutzpraktiken externer Websites, die über Links von dieser Website aus zugänglich sind.
        </p>
      </section>
      
      <section className="impressum-section">
        <h2>Urheberrecht:</h2>
        <p>
          Die auf dieser Website veröffentlichten Inhalte und Werke unterliegen dem deutschen Urheberrecht. Jede Art der Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechts bedarf der vorherigen schriftlichen Zustimmung des jeweiligen Urhebers bzw. Autors.
        </p>
      </section>
    </div>
  );
};

export default Impressum;
