import React from "react";
import "./digital-business-card.css"; // Importiere die spezifischen Stile für die Digital Business Card
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faTiktok,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";

const DigitalBusinessCard = () => {
  return (
    <div className="container">
      <main className="center-content">
        <h1>Digitale Visitenkarte</h1>{" "}
        {/* Die Überschrift bleibt im Hauptbereich */}
        <div className="business-card">
          <div className="logo-container">
            <img src="/IMG_1498.JPG" alt="Logo" className="logo" />
          </div>
          <p className="card-text">
            Vielen Dank für Ihr Interesse an Özer Media! Hier ist unsere
            digitale Visitenkarte:
          </p>
          <div className="contact-info">
            <p>
              <strong>Email:</strong>{" "}
              <a href="mailto:oezermedia@outlook.com" className="contact-link">
                oezermedia@outlook.com
              </a>
            </p>
            <p>
              <strong>Telefon:</strong>{" "}
              <a href="tel:+4962115962914" className="contact-link">
                +49 621 15962914
              </a>
            </p>
          </div>
          <p className="follow-text">Folge uns in den sozialen Medien:</p>
          <div className="social-icons">
            {socialMediaLinks.map((link, index) => (
              <a
                key={index}
                href={link.href}
                target="_blank"
                rel="noopener noreferrer"
                className="icon-link"
              >
                <FontAwesomeIcon icon={link.icon} />
              </a>
            ))}
          </div>
        </div>
      </main>
    </div>
  );
};

const socialMediaLinks = [
  {
    href: "https://facebook.com/oezermedia",
    icon: faFacebook,
  },
  {
    href: "https://instagram.com/oezermedia",
    icon: faInstagram,
  },
  {
    href: "https://www.tiktok.com/@oezermedia",
    icon: faTiktok,
  },
  {
    href: "https://wa.me/message/BVMNVN4ARGJ4H1?src=qr",
    icon: faWhatsapp,
  },
  {
    href: "mailto:oezermedia@outlook.com",
    icon: faEnvelope,
  },
  {
    href: "tel:+49 621 15962914",
    icon: faPhone,
  },
];

export default DigitalBusinessCard;
