import React from "react";

function Services() {
  return (
    <div className="section">
      <h2 className="section-title">Unsere Dienstleistungen</h2>
      <div className="service">
        <h3 className="service-title">Eventfotografie</h3>
        <p className="service-description">
          Unser Team von Eventfotografen ist darauf spezialisiert, Momente einzufangen und Ihre Veranstaltung in lebendigen Bildern festzuhalten. Von Hochzeiten über Firmenveranstaltungen bis hin zu Konzerten sind wir für Sie da, um Ihre wichtigsten Momente zu dokumentieren.
        </p>
      </div>
      <div className="service">
        <h3 className="service-title">Hochzeitsfotografie</h3>
        <p className="service-description">
          Wir verstehen, dass Ihre Hochzeit einer der wichtigsten Tage Ihres Lebens ist. Unser Hochzeitsfotografieteam arbeitet diskret, um die romantischen, emotionalen und authentischen Momente Ihres besonderen Tages einzufangen. Jede Hochzeit ist einzigartig, und wir möchten sicherstellen, dass Ihre Erinnerungen für immer festgehalten werden.
        </p>
      </div>
      <div className="service">
        <h3 className="service-title">Businessfotografie</h3>
        <p className="service-description">
          Professionelle Bilder sind entscheidend, um den Erfolg Ihres Unternehmens zu steigern. Unsere Businessfotografen arbeiten eng mit Ihnen zusammen, um Ihre Marke zu verstehen und hochwertige Bilder für Ihre Werbematerialien, Websites, Veranstaltungen und vieles mehr zu erstellen.
        </p>
      </div>
      <div className="service">
        <h3 className="service-title">Familienporträts</h3>
        <p className="service-description">
          Familienporträts sind eine wertvolle Erinnerung für Sie und Ihre Lieben. Wir nehmen uns die Zeit, um Ihre einzigartigen Beziehungen und Persönlichkeiten einzufangen und schöne, zeitlose Porträts zu erstellen, die für Generationen überdauern.
        </p>
      </div>
      <div className="service">
        <h3 className="service-title">Produktfotografie</h3>
        <p className="service-description">
          Professionelle Produktfotos sind entscheidend für den Erfolg Ihres Unternehmens. Wir bieten hochwertige Produktfotografie an, um Ihre Produkte optimal zu präsentieren und das Interesse Ihrer Kunden zu wecken.
        </p>
      </div>
      <div className="service">
        <h3 className="service-title">Porträtfotografie</h3>
        <p className="service-description">
          Unsere Porträtfotografen nehmen sich die Zeit, um Ihre Persönlichkeit einzufangen und einzigartige Porträts zu erstellen, die Ihre Individualität und Schönheit hervorheben.
        </p>
      </div>
      {/* Fügen Sie weitere Dienstleistungen hinzu */}
    </div>
  );
}

export default Services;
