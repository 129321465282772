import React, { useState } from "react";
import "./FamilyGallery.css"; // Importiere die CSS-Datei

function FamilyGallery() {
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);

  const photos = [
    { src: "/images/familyImages/OEZMD-25.webp" },
    { src: "/images/familyImages/OEZMD-94.webp" },
    { src: "/images/familyImages/OEZMD-99.webp" },
    { src: "/images/familyImages/OEZMD-102.webp" },
    { src: "/images/familyImages/OEZMD-122.webp" },
    { src: "/images/familyImages/OEZMD-134.webp" },
    { src: "/images/familyImages/OEZMD-147.webp" },
    { src: "/images/familyImages/OEZMD-169.webp" },
    { src: "/images/familyImages/OEZMD-1732.webp" },
    { src: "/images/familyImages/OEZMD-176.webp" },
    { src: "/images/familyImages/OEZMD-191.webp" },
    { src: "/images/familyImages/OEZMD-199.webp" },
    { src: "/images/familyImages/OEZMD-2002.webp" },
    { src: "/images/familyImages/OEZMD-200.webp" },
    { src: "/images/familyImages/OEZMD-216.webp" },
    { src: "/images/familyImages/OEZMD-241.webp" },
    { src: "/images/familyImages/OEZMD-255.webp" },
    { src: "/images/familyImages/OEZMD-343.webp" },
    { src: "/images/familyImages/OEZMD-367.webp" },
    { src: "/images/familyImages/OEZMD-397.webp" },
    { src: "/images/familyImages/OEZMD-407.webp" },
    { src: "/images/familyImages/OEZMD-444.webp" },
    { src: "/images/familyImages/OEZMD-530.webp" },
    { src: "/images/familyImages/OEZMD-583.webp" },
    // Weitere Familienbilder hinzufügen...
  ];

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <div>
      <h2>Family Gallery</h2>
      <div className="family-gallery">
        {photos.map((photo, index) => (
          <div key={index} className="family-gallery-img">
            <img
              src={process.env.PUBLIC_URL + photo.src}
              alt={`Photo ${index + 1}`}
              onClick={() => openLightbox(index)}
            />
          </div>
        ))}
      </div>
      {viewerIsOpen && (
        <div className="overlay" onClick={closeLightbox}>
          <div className="modal" onClick={(e) => e.stopPropagation()}>
            <button className="modal-close" onClick={closeLightbox}>
              Schließen
            </button>
            <img
              src={process.env.PUBLIC_URL + photos[currentImage].src}
              alt={`Photo ${currentImage + 1}`}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default FamilyGallery;
