import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer>
      <div className="container">
        <p>
          &copy; {new Date().getFullYear()} Özer Media. All rights reserved.
        </p>
      </div>
      <div className="legal-links">
        <Link to="/privacy-policy">Datenschutz</Link>
        <span></span> {/* Trennzeichen zwischen den Links */}
        <Link to="/imprint">Impressum</Link>
      </div>
    </footer>
  );
}

export default Footer;
